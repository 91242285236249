<template>
	<div class="flex notfind">
		<div class="notfind-box">
			<img src="@/assets/images/public/404.png" alt="" />
			<h3>Sorry,页面不存在！</h3>
			<p>您可以去 <em @click="goIndex">首页</em> 看看~</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		},
		mounted() {
			this.$tool.resize();
			this.$nextTick(() => {
				window.addEventListener("resize", () => this.$tool.resize(), false);
			});
		},
		methods: {
			goIndex() {
				this.$router.replace({
					name: "index",
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.notfind {
		height: 100%;
		text-align: center;
		justify-content: center;
		// background-color: #082f72;
		// color: #fff;
		background-color: #ffffff;
		color: #333;
		& h3 {
			font-size: 28px;
			line-height: 48px;
		}
		& p {
			font-size: 18px;
			line-height: 36px;
		}
		& img {
			width: 543px;
			height: 482px;
		}
		& em {
			text-decoration: underline;
			cursor: pointer;
			transition-duration: 0.4s;
			&:hover {
				color: rgba($color: #082f72, $alpha: 0.5);
			}
		}
	}
</style>